/**
 * @fileoverview gRPC-Web generated client stub for grass.layers.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: apis/layers/v1/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as apis_layers_v1_api_pb from '../../../apis/layers/v1/api_pb'; // proto import: "apis/layers/v1/api.proto"


export class LayersAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSaveLayer = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.LayersAPI/SaveLayer',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.SaveLayerRequest,
    apis_layers_v1_api_pb.SaveLayerResponse,
    (request: apis_layers_v1_api_pb.SaveLayerRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.SaveLayerResponse.deserializeBinary
  );

  saveLayer(
    request: apis_layers_v1_api_pb.SaveLayerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.SaveLayerResponse>;

  saveLayer(
    request: apis_layers_v1_api_pb.SaveLayerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.SaveLayerResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.SaveLayerResponse>;

  saveLayer(
    request: apis_layers_v1_api_pb.SaveLayerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.SaveLayerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.LayersAPI/SaveLayer',
        request,
        metadata || {},
        this.methodDescriptorSaveLayer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.LayersAPI/SaveLayer',
    request,
    metadata || {},
    this.methodDescriptorSaveLayer);
  }

  methodDescriptorGetLayer = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.LayersAPI/GetLayer',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.GetLayerRequest,
    apis_layers_v1_api_pb.GetLayerResponse,
    (request: apis_layers_v1_api_pb.GetLayerRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.GetLayerResponse.deserializeBinary
  );

  getLayer(
    request: apis_layers_v1_api_pb.GetLayerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.GetLayerResponse>;

  getLayer(
    request: apis_layers_v1_api_pb.GetLayerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.GetLayerResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.GetLayerResponse>;

  getLayer(
    request: apis_layers_v1_api_pb.GetLayerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.GetLayerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.LayersAPI/GetLayer',
        request,
        metadata || {},
        this.methodDescriptorGetLayer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.LayersAPI/GetLayer',
    request,
    metadata || {},
    this.methodDescriptorGetLayer);
  }

  methodDescriptorListLayers = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.LayersAPI/ListLayers',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.ListLayersRequest,
    apis_layers_v1_api_pb.ListLayersResponse,
    (request: apis_layers_v1_api_pb.ListLayersRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.ListLayersResponse.deserializeBinary
  );

  listLayers(
    request: apis_layers_v1_api_pb.ListLayersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.ListLayersResponse>;

  listLayers(
    request: apis_layers_v1_api_pb.ListLayersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.ListLayersResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.ListLayersResponse>;

  listLayers(
    request: apis_layers_v1_api_pb.ListLayersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.ListLayersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.LayersAPI/ListLayers',
        request,
        metadata || {},
        this.methodDescriptorListLayers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.LayersAPI/ListLayers',
    request,
    metadata || {},
    this.methodDescriptorListLayers);
  }

  methodDescriptorDeleteLayer = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.LayersAPI/DeleteLayer',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.DeleteLayerRequest,
    apis_layers_v1_api_pb.DeleteLayerResponse,
    (request: apis_layers_v1_api_pb.DeleteLayerRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.DeleteLayerResponse.deserializeBinary
  );

  deleteLayer(
    request: apis_layers_v1_api_pb.DeleteLayerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.DeleteLayerResponse>;

  deleteLayer(
    request: apis_layers_v1_api_pb.DeleteLayerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.DeleteLayerResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.DeleteLayerResponse>;

  deleteLayer(
    request: apis_layers_v1_api_pb.DeleteLayerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.DeleteLayerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.LayersAPI/DeleteLayer',
        request,
        metadata || {},
        this.methodDescriptorDeleteLayer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.LayersAPI/DeleteLayer',
    request,
    metadata || {},
    this.methodDescriptorDeleteLayer);
  }

}

export class APIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.API/Create',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.CreateRequest,
    apis_layers_v1_api_pb.CreateResponse,
    (request: apis_layers_v1_api_pb.CreateRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.CreateResponse.deserializeBinary
  );

  create(
    request: apis_layers_v1_api_pb.CreateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.CreateResponse>;

  create(
    request: apis_layers_v1_api_pb.CreateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.CreateResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.CreateResponse>;

  create(
    request: apis_layers_v1_api_pb.CreateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.CreateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.API/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.API/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorLayer = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.API/Layer',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.LayerRequest,
    apis_layers_v1_api_pb.LayerResponse,
    (request: apis_layers_v1_api_pb.LayerRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.LayerResponse.deserializeBinary
  );

  layer(
    request: apis_layers_v1_api_pb.LayerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.LayerResponse>;

  layer(
    request: apis_layers_v1_api_pb.LayerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.LayerResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.LayerResponse>;

  layer(
    request: apis_layers_v1_api_pb.LayerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.LayerResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.API/Layer',
        request,
        metadata || {},
        this.methodDescriptorLayer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.API/Layer',
    request,
    metadata || {},
    this.methodDescriptorLayer);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.API/List',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.ListRequest,
    apis_layers_v1_api_pb.ListResponse,
    (request: apis_layers_v1_api_pb.ListRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.ListResponse.deserializeBinary
  );

  list(
    request: apis_layers_v1_api_pb.ListRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.ListResponse>;

  list(
    request: apis_layers_v1_api_pb.ListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.ListResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.ListResponse>;

  list(
    request: apis_layers_v1_api_pb.ListRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.ListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.API/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.API/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/grass.layers.v1.API/Delete',
    grpcWeb.MethodType.UNARY,
    apis_layers_v1_api_pb.DeleteRequest,
    apis_layers_v1_api_pb.DeleteResponse,
    (request: apis_layers_v1_api_pb.DeleteRequest) => {
      return request.serializeBinary();
    },
    apis_layers_v1_api_pb.DeleteResponse.deserializeBinary
  );

  delete(
    request: apis_layers_v1_api_pb.DeleteRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_layers_v1_api_pb.DeleteResponse>;

  delete(
    request: apis_layers_v1_api_pb.DeleteRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.DeleteResponse) => void): grpcWeb.ClientReadableStream<apis_layers_v1_api_pb.DeleteResponse>;

  delete(
    request: apis_layers_v1_api_pb.DeleteRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_layers_v1_api_pb.DeleteResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.layers.v1.API/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.layers.v1.API/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

}

